import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

const TagListContainer = styled('div')`
  display: block;
  margin: 10px 0 20px 0;
  font-size: 13px;
`
//background-color: #868e96;
const TagContainer = styled('span')`
  margin-right: 10px;
  text-transform: lowercase;
  user-select: none;
  border: 1px solid #ccc;
  color: #222;
  border-radius: 6px;
  padding: 6px 12px;
`

const Tag = ({ tag }) => <TagContainer>{tag}</TagContainer>

const TagList = ({ tags }) => (
  <>
    {tags && (
      <TagListContainer>
        {tags.map((tag, i) => (
          <Tag tag={tag} key={i} />
        ))}
      </TagListContainer>
    )}
  </>
)

TagList.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default TagList
