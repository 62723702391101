import React from 'react'
import MDXRenderer from 'gatsby-mdx/mdx-renderer'
import Img from 'gatsby-image'
import { Link } from 'gatsby'

import './Markdown.scss'
import styles from './ContentSection.module.scss'
import ShareBtn from 'components/shared/ShareBtn'
import TagList from 'components/Blog/tags/TagList'

export default ({ post, showMap, previous, next }) => (
  <div
    id="blog"
    className={[
      styles.blogContainer,
      showMap != null && styles.mapPost,
      showMap && styles.hidePostMobile,
    ].join(' ')}
  >
    <Img
      sizes={post.frontmatter.featuredImage.childImageSharp.sizes}
      className={styles.featuredImg}
    />
    <div className={styles.postTitleContainer}>
      <h1>{post.frontmatter.title}</h1>
      <div className={styles.postDetailsWrapper}>
        <div>
          <div className={styles.postDate}>{post.frontmatter.date}</div>
          <TagList tags={post.frontmatter.tags} />
        </div>
        <div>
          <ShareBtn link={'https://melo.to/blog' + post.fields.slug} />
        </div>
      </div>
    </div>
    <div className={styles.postWrapper}>
      <MDXRenderer>{post.code.body}</MDXRenderer>
    </div>

    <ul className={styles.readNextList}>
      <li>
        {previous && (
          <Link to={`/blog${previous.fields.slug}`} rel="prev">
            ← {previous.frontmatter.title}
          </Link>
        )}
      </li>
      <li>
        {next && (
          <Link to={`/blog${next.fields.slug}`} rel="next">
            {next.frontmatter.title} →
          </Link>
        )}
      </li>
    </ul>
  </div>
)
