import React from 'react'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'

import { MapIcon, ArticleIcon } from 'components/shared/Icons'
import BlogLayout from 'layouts/Blog'
import { ContentSection, MapSection } from 'components/Blog/Post'
import SEO from 'components/shared/SEO'

class MapPostTemplate extends React.Component {
  state = {
    showMap: false,
  }
  render() {
    const { mdx, json } = this.props.data
    const post = mdx
    const { siteTitle, blogUrl } = this.props.data.site.siteMetadata
    const { previous, next } = this.props.pageContext
    const { points, lines } = json

    let { showMap } = this.state
    return (
      <>
        <div
          className="toggle-map-button"
          onClick={() => this.setState({ showMap: !showMap })}
        >
          {showMap ? <MapIcon /> : <ArticleIcon />}
          show {showMap ? 'article' : 'map'}
        </div>

        <div className="map-post-wrapper">
          <ContentSection
            post={post}
            showMap={showMap}
            previous={previous}
            next={next}
          />

          <MapSection showMap={showMap} points={points} lines={lines} />
        </div>
      </>
    )
  }
}

export default ({ location, data, pageContext }) => (
  <BlogLayout location={location} title={data.mdx.frontmatter.title}>
    <SEO
      title={data.mdx.frontmatter.title}
      image={
        data.site.siteMetadata.blogUrl +
        data.mdx.frontmatter.featuredImage.childImageSharp.sizes.src
      }
      description={data.mdx.frontmatter.description || data.mdx.excerpt}
      pathname={location.pathname}
    />
    <MapPostTemplate data={data} pageContext={pageContext} />
  </BlogLayout>
)

export const pageQuery = graphql`
  query($slug: String!, $pointsID: String) {
    site {
      siteMetadata {
        title
        blogUrl
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      fields {
        slug
      }
      code {
        body
      }
      excerpt(pruneLength: 160)
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        tags
        featuredImageMeta
        featuredImage {
          childImageSharp {
            sizes(maxWidth: 630) {
              ...GatsbyImageSharpSizes
            }
          }
        }
      }
    }
    json(id: { eq: $pointsID }) {
      lines {
        name
        coordinates {
          lat
          lng
        }
      }
      points {
        name
        coordinates
        order
        icon
      }
    }
  }
`
