import React, { PureComponent } from 'react'

import './MarkerInfo.scss'

export default class CityInfo extends PureComponent {
  render() {
    const { info } = this.props

    return (
      <div className="marker-info">
        {info.pic && <img width={240} src={info.pic} className="marker-img" />}
        <div>{info.name}</div>
      </div>
    )
  }
}
