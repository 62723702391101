import React, { Component } from 'react'
import ReactMapGL, { Marker, Popup } from 'react-map-gl'
import CityPin from './CityPin'
import CityInfo from './MarkerInfo'
import { fromJS } from 'immutable'
import PolylineOverlay from './PolyLineOverlay'
import './BaseMap.scss'

const MAPBOX_TOKEN =
  'pk.eyJ1IjoiY3Rob3lsZSIsImEiOiJjaXIyMWRmZ2UwMDAwenZrcXR2eHE4c2tmIn0.H457fh_sSEbaBzIBskg6yQ'
const LIGHT_STYLE = fromJS('mapbox://styles/mapbox/light-v9')

const pointLayer = fromJS({
  id: 'point',
  source: 'point',
  type: 'circle',
  paint: {
    'circle-radius': 10,
    'circle-color': '#007cbf',
  },
})

export default class BaseMap extends Component {
  constructor(props) {
    super(props)
    this.state = {
      mapStyle: LIGHT_STYLE,
      viewState: {
        width: 600,
        height: 400,
        latitude: 38.892962,
        longitude: -77.040517,
        zoom: 11,
      },
      popupInfo: null,
    }
  }

  _onViewportChange = viewState => this.setState({ viewState })

  _renderMarker = (station, i) => {
    const { name, coordinates, icon } = station

    return (
      <Marker key={i} longitude={coordinates[1]} latitude={coordinates[0]}>
        <CityPin
          size={30}
          icon={icon}
          marker={station}
          onClick={() => this.setState({ popupInfo: station })}
        />
      </Marker>
    )
  }

  _renderLines = (line, i) => {
    const { name, coordinates } = line
    return <PolylineOverlay points={coordinates} />
  }

  _renderPopup() {
    const { popupInfo } = this.state

    return (
      popupInfo && (
        <Popup
          tipSize={5}
          anchor="bottom"
          longitude={popupInfo.coordinates[1]}
          latitude={popupInfo.coordinates[0]}
          closeOnClick={false}
          onClose={() => this.setState({ popupInfo: null })}
        >
          <CityInfo info={popupInfo} />
        </Popup>
      )
    )
  }

  render() {
    let { markers, lines } = this.props
    let { mapStyle } = this.state
    return (
      <>
        {' '}
        {typeof window == 'undefined' ? (
          <p>window is undefined</p>
        ) : (
          <ReactMapGL
            {...this.state.viewState}
            mapboxApiAccessToken={MAPBOX_TOKEN}
            width="100%"
            height="100%"
            mapStyle={mapStyle}
            onLoad={this._onMapLoad}
            onViewportChange={this._onViewportChange}
          >
            {this._renderPopup()}
            {markers && markers.map(this._renderMarker)}
            {lines && lines.map(this._renderLines)}
          </ReactMapGL>
        )}
      </>
    )
  }
}
