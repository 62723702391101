import React, { PureComponent } from 'react'
import { CanvasOverlay } from 'react-map-gl'

export default class PolylineOverlay extends PureComponent {
  _redraw({ width, height, ctx, isDragging, project, unproject }) {
    const {
      points,
      color = '#5E31BF',
      lineWidth = 2,
      renderWhileDragging = true,
    } = this.props
    ctx.clearRect(0, 0, width, height)
    ctx.globalCompositeOperation = 'lighter'

    if ((renderWhileDragging || !isDragging) && points) {
      ctx.lineWidth = lineWidth
      ctx.strokeStyle = color
      ctx.beginPath()
      points.forEach(point => {
        const pixel = project([point['lng'], point['lat']])
        ctx.lineTo(pixel[0], pixel[1])
      })
      ctx.stroke()
    }
  }

  render() {
    return (
      <CanvasOverlay
        redraw={this._redraw.bind(this)}
        captureClick={false}
        onClick={() => console.log('on click')}
        style={{ cursor: 'pointer' }}
      />
    )
  }
}
