import React from 'react'

import './MapSection.scss'
import BaseMap from 'components/Blog/Map/BaseMap'

export default ({ showMap, points, lines }) => (
  <div className={`blog-map ${showMap ? 'show-map-mobile' : ''}`}>
    <div className="map-wrapper">
      <BaseMap markers={points} lines={lines} />
    </div>
  </div>
)
